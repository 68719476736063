@use '@angular/material' as mat;
@import 'app-theme.scss';
@import './styles/fonts.scss';
@import './styles/buttons.scss';
@import './styles/dialogs.scss';
@import './styles/password.scss';
@import './styles/static-page.scss';
@import './styles/stay-informed.scss';
@import './styles/age-up-thank-you.scss';
@import './styles/activities.scss';
@import './styles/dashboard.scss';
@import './styles/session-expired.scss';

$custom-typography: mat.define-typography-config($font-family: 'Untitled-Sans-Regular');
@include mat.core($custom-typography);

html,
body {
    margin: 0;
    padding: 0;
    height: 100vh;
    font-size: 16px;
    color: mat.get-color-from-palette($app-theme, 200);
}

body {
    font-family: 'Untitled-Sans-Regular', sans-serif;
    overflow-y: scroll;
}

@media only screen and (max-width: 1024px) {
    html {
        font-size: 14px;
    }
}

@media only screen and (max-width: 768px) {
    html {
        font-size: 12px;
    }
}

.wrapper {
    height: 100vh;
    margin-bottom: -17rem; // footer's height
    padding-bottom: 17rem; // footer's height
    padding-top: 6.25rem; // header's height
    box-sizing: border-box;
}

.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100%;
}

.section {
    width: 100%;

    &_last {
        margin-bottom: 2rem;
    }
}

.section-title {
    text-align: center;
    margin: 4.375rem 0;
}

.content {
    margin: 0 auto;
    padding: 0 1.5625rem;

    &_wide {
        max-width: 1200px;
    }

    &_medium {
        max-width: 1000px;
    }

    &_tight {
        max-width: 800px;
    }
}

.subject-panel-container,
.ddp-admin-action-panel {
    margin: 0 auto;
    padding: 1rem 1.5625rem;
    max-width: 1000px;
}

a {
    cursor: pointer;
    text-decoration: none;
    color: mat.get-color-from-palette($app-theme, 200);
}

a:focus {
    text-decoration: underline;
}

h1,
h2,
h3,
h4 {
    font-family: 'Source Serif Pro', serif;
}

h1 {
    font-size: 2.5rem;
    line-height: 3.125rem;
}

h2 {
    font-size: 2rem;
    line-height: 3.125rem;
}

ol li,
ul li,
p {
    font-size: 18px;
    line-height: 32px;
}

.Link {
    font-family: 'Untitled-Sans-Medium', sans-serif;
    color: mat.get-color-from-palette($app-theme, 250);
    text-decoration: none;

    &_italic {
        font-style: italic;
    }
}

.Link:hover {
    text-decoration: underline;
}

.Link:active {
    color: mat.get-color-from-palette($app-theme, 300);
    text-decoration: underline;
}

.no-margin {
    margin: 0;
}

.only-margin-bottom {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
}

.h2-big {
    font-size: 2.5rem;
    line-height: 3.125rem;
}

.ddp-close-button:hover {
    color: mat.get-color-from-palette($app-theme, 400);
    transition: 0.3s;
}

.center {
    text-align: center;
}

.light {
    font-family: 'Untitled-Sans-Light', sans-serif;
}

.regular {
    font-family: 'Untitled-Sans-Regular', sans-serif;
}

.bold,
.medium {
    font-family: 'Untitled-Sans-Medium', sans-serif;
}

.normal {
    font-family: 'Untitled-Sans-Regular', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
}

.italic {
    font-style: italic;
}

@media only screen and (max-width: 480px) {
    .content {
        padding: 0 1rem;
    }
}
