@use '@angular/material' as mat;
@import '../app-theme.scss';

// common activity styles
.section-spinner {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: center;
}

.static-spinner {
    padding-bottom: 8rem;
}

.consent-subtitle {
    font-size: 1.875rem;
    line-height: 3.5rem;
    text-decoration: underline;
}

.main_activity {
    padding-bottom: 6.25rem;
    padding-top: 1rem;
    box-sizing: border-box;

    .content_tight,
    .subject-panel-container,
    .ddp-admin-action-panel {
        max-width: 750px;
    }
}

.main_sticky {
    padding-top: 5rem;
}

.sticky-section {
    z-index: 2;
    position: fixed;
    top: 6.25rem; // header's height
    background-color: mat.get-color-from-palette($app-theme, 250);
    transition: all 200ms linear;
}

.sticky-section_shadow {
    box-shadow: 0 20px 20px -20px rgba(0, 0, 0, 0.2);
}

.sticky-block {
    margin: 0.8rem 0;

    .sticky__text {
        font-size: 1.0625rem;
        line-height: 1.375rem;
        color: mat.get-color-from-palette($app-theme, 100);
        font-weight: 200;
    }

    .Link {
        color: mat.get-color-from-palette($app-theme, 100);
    }
}

@media only screen and (max-width: 850px) {
    .sticky-section {
        display: none;
    }

    .main_sticky {
        padding-top: 1rem;
    }
}

// steps
.activity-steps {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    grid-gap: 1rem;
    margin: 3rem 0;
}

.activity-step {
    font-size: 1.5rem;
    line-height: 2.25rem;
    font-weight: 600;
    cursor: initial;
    color: mat.get-color-from-palette($app-theme, 650);
    color: #bbbbbe;
    font-family: 'Source Serif Pro', serif !important;
}

.activity-step.completed {
    cursor: pointer;
    color: mat.get-color-from-palette($app-theme, 700);
    color: #59585f;
}

.activity-step.active.completed {
    color: mat.get-color-from-palette($app-theme, 200);
}

@media only screen and (max-width: 600px) {
    .activity-steps {
        grid-auto-flow: row;
    }
}

// activity lists
.release-list-item {
    margin-left: 2rem;
}

.ddp-list {
    padding: 0 0 0 1.5rem;
    font-size: 1.5rem;
    line-height: 2.875rem;
}

.ddp-li {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 2.875rem;
}

.ddp-block-title-bold {
    font-weight: 600;
}

.ddp-block-body {
    font-size: 18px !important;
    line-height: 32px !important;
    color: mat.get-color-from-palette($app-theme, 700);
    margin: 1rem 0 1rem -1.5625rem;
    font-weight: 400;
}

.ddp-simple-list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    .ddp-question-prompt {
        font-size: 1.375rem;
        line-height: 2.25rem;
        font-family: inherit;
    }
}

.ddp-simple-list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    .ddp-question-prompt {
        font-size: 1.375rem;
        line-height: 2.25rem;
        font-family: inherit;
    }
}

.ul-flat-list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li::before {
        content: "— ";
    }
}

.inner-ul-flat-list {
    list-style-type: none;
    padding: 0;

    p::before {
        content: "— ";
    }
}

.ol-flat-list {
    font-weight: 600;
    color: mat.get-color-from-palette($app-theme, 200);

    p {
        color: mat.get-color-from-palette($app-theme, 700);
    }
}

.simple-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

// required question
.ddp-required-question-prompt:after {
    content: ' *';
}

// buttons layout
.activity-buttons {
    display: flex;
    justify-content: space-between;
    margin: 4rem 0 0 0;
}

.button_right {
    margin-left: auto;
}

// question text
.ddp-question-prompt {
    font-family: 'Source Serif Pro', serif !important;
    font-size: 1.5rem;
    line-height: 2.875rem;
    margin: 1.5rem 0 0.5rem 0;
}

// questions
.ddp-activity-question {

    mat-checkbox,
    mat-radio-button {
        color: mat.get-color-from-palette($app-theme, 200) !important;
    }

    .mat-radio-label {
        align-items: normal !important;
        width: inherit !important;
    }

    .mat-list-text {
        width: inherit !important;
    }
}

// consent agree question
.consent-agree {
    margin: 0 0 -1.2rem 0;
}

// agreement question
.ddp-agreement-text {
    white-space: normal;
    color: mat.get-color-from-palette($app-theme, 700);
}

.ddp-agreement {
    .mat-checkbox-inner-container {
        top: 4px;
    }
}

.ddp-content__agreement {
    font-family: 'Source Serif Pro', serif;
    font-size: 1.5rem;
    line-height: 2.875rem;
}

.ddp-agreement-text {
    margin: 0;
}

// picklist
ddp-activity-picklist-answer {
    font-weight: 400;
}

.mat-checkbox-label,
.mat-radio-label-content {
    font-size: 1.2rem;
    line-height: 1.2rem !important;
    color: mat.get-color-from-palette($app-theme, 1300);
}

.mat-checkbox-layout {
    align-items: flex-start !important;
}

.mat-checkbox-inner-container {
    height: 1.2rem !important;
    width: 1.2rem !important;
    margin-top: 0 !important;
}

.mat-radio-label {
    margin: 0.5rem 0;
    align-items: unset !important;
}

.mat-radio-label-content {
    font-family: 'Untitled-Sans-Regular', sans-serif;
}

.picklist {
    .ddp-date-field-margin:first-child {
        margin: 0 1.5rem 0 0;
    }

    .ddp-date-field-margin:last-child {
        margin: 0;
    }
}

.ddp-picklist-option-list-item {
    height: auto !important;
    margin-bottom: 0.5rem;
}

.mat-list .mat-list-item .mat-list-item-content {
    display: block !important;
    padding: 0 4px !important;
}

.ddp-helper {
    margin: 0;
    font-size: 1.125rem;
    line-height: 1.4375rem;
    color: mat.get-color-from-palette($app-theme, 1300);
    font-family: 'Untitled-Sans-Regular', sans-serif;
}

.ddp-counter-color {
    color: mat.get-color-from-palette($app-theme, 250);
}

// native dropdown
select {
    font-size: 1.2rem;
    color: mat.get-color-from-palette($app-theme, 1300);
    background-color: rgba(255, 255, 255, 0.9);
    width: 100%;
    padding: 0;
    margin-top: 1rem;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, .42);
    border-radius: 0;
    height: 2.65rem;
    transition: all .3s;
    font-family: inherit;
    cursor: pointer;
}

select:disabled {
    color: rgba(0, 0, 0, 0.38);
    border-bottom: 1px dashed rgba(0, 0, 0, 0.38);
    cursor: auto;
}

// material dropdown
.mat-select-value {
    color: mat.get-color-from-palette($app-theme, 700) !important;
}

.mat-form-field-label {
    font-size: 1.0rem !important;
}

// input
.mat-form-field-infix {
    font-size: 1.2rem;
}

// error message
.ErrorMessage {
    font-size: 1.2rem;
    line-height: 1.875rem;
    margin: 1rem 0;
    color: mat.get-color-from-palette($app-theme, 1200);
    border-top: 1px solid mat.get-color-from-palette($app-theme, 1200);
}

.ErrorMessage a {
    text-decoration: underline !important;
}

.ddp-activity-validation {
    .ErrorMessageList {
        list-style-type: square;
    }

    .ErrorMessageItem {
        font-size: 1.2rem;
        color: mat.get-color-from-palette($app-theme, 1200);
        margin: .3rem 0;
        padding: 0 0 0 0;
    }
}

// material error message
.mat-error {
    font-size: 0.9rem;
    color: mat.get-color-from-palette($app-theme, 1200);
}

// info box
.infobox {
    border: 2px solid mat.get-color-from-palette($app-theme, 250);
    border-radius: 8px;
    background-color: rgba(113, 84, 255, 0.06);
    padding: 1rem;
}

// institutions
.ddp-institutions-form {
    margin: 2rem 0;
}

.ddp-address-embedded__title,
.ddp-institutions-form__title {
    font-family: 'Source Serif Pro', serif;
    font-size: 1.5rem;
    line-height: 2.875rem;
    margin: 0;
}

.ddp-address-embedded__subtitle,
.ddp-institutions-form__subtitle {
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 0 0 0.5rem 0;
    color: mat.get-color-from-palette($app-theme, 1300);
}

.boolean-answer-CONSENT_BLOOD,
.boolean-answer-CONSENT_TISSUE,
.boolean-answer-PARENTAL_CONSENT_BLOOD,
.boolean-answer-PARENTAL_CONSENT_TISSUE,
.boolean-answer-CONSENT_ASSENT_BLOOD,
.boolean-answer-CONSENT_ASSENT_TISSUE {
    .ddp-question-prompt {
        font-size: 18px;
        line-height: 32px;
        font-family: 'Untitled-Sans-Regular', sans-serif !important;
    }
}

.composite-answer-CHILD_BRAIN_CANCER_GRADE_CHANGED_LIST,
.composite-answer-BRAIN_CANCER_GRADE_CHANGED_LIST {

    .picklist-answer-CHILD_OTHER_BRAIN_CANCER_GRADE,
    .picklist-answer-OTHER_BRAIN_CANCER_GRADE {
        select {
            margin-bottom: 1.25rem;
        }
    }
}

.composite-answer-CHILD_OTHER_CANCER_LIST,
.composite-answer-OTHER_CANCER_LIST {

    .mat-form-field-type-mat-native-select .mat-input-element {
        padding-left: 5px;
    }

    .ddp-answer-container {
        align-items: center;

        .ddp-answer-field {
            margin-right: 1.5rem;
            flex-grow: 1;

            .mat-form-field {
                width: 100%;
            }
        }

        .ddp-answer-field:last-child {
            margin-right: 0;
        }
    }

    .ddp-composite-text {
        width: 100%;
        margin-right: 2rem;
    }

    @media only screen and (max-width: 700px) {
        .ddp-answer-container {
            flex-direction: column !important;
            align-items: initial !important;

            .ddp-answer-field {
                margin-right: 0 !important;
            }
        }

        .ddp-composite-text {
            margin-right: 0;
        }
    }
}

.ddp-textarea {
    max-height: 10rem !important;
}

// nested picklist
.ddp-nested-picklist {
    margin-left: 3rem;

    &__title {
        margin: 1rem 0 0.5rem 0;
        font-family: 'Source Serif Pro', serif;
        font-size: 1.2rem;
        line-height: 2rem;
    }
}

.picklist-answer-WHO_FILLING {

    .mat-radio-container {
        margin-top: 0.3rem;
    }

    .mat-checkbox-label,
    .mat-radio-label-content {
        font-size: 1.2rem;
        line-height: 1.9rem !important;
    }
}
