@font-face {
    font-family: 'Untitled-Sans-Medium';
    src: url('../assets/fonts/UntitledSans-Medium.woff2') format('woff2'),
        url('../assets/fonts/UntitledSans-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Untitled-Sans-Regular';
    src: url('../assets/fonts/UntitledSans-Regular.woff2') format('woff2'),
        url('../assets/fonts/UntitledSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Untitled-Sans-Light';
    src: url('../assets/fonts/UntitledSans-Light.woff2') format('woff2'),
        url('../assets/fonts/UntitledSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}