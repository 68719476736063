@import '../app-theme.scss';

.static-page-content-section {
    margin: 4rem 0;
}

.password-section {
    margin: 4rem 0;
}

.password-section__question {
    font-family: "Source Serif Pro", serif;
    font-size: 1.5rem;
    line-height: 2.875rem;
    margin: 1.5rem 0 0.5rem 0;
}

.password-section__button {
    margin: 3rem 0 0 0;
}