@use '@angular/material' as mat;
@import '../app-theme.scss';

.dashboard-title-section {
    padding: 2rem 0 0 0;
    background-color: mat.get-color-from-palette($app-theme, 800);
}

.dashboard-section {
    margin-bottom: 10rem;
}

.dashboard-content {
    margin: 2rem 0 0 0;
}

.dashboard-content_table {
    margin: 2rem 0;
}

.infobox_dashboard {
    padding: 1.5rem 2rem;
    position: relative;

    .close-button {
        position: absolute;
        top: 0;
        right: 0;
    }
}

.infobox-text_small {
    font-size: 1rem;
    line-height: 1.5rem;
    color: mat.get-color-from-palette($app-theme, 200);
}

.ddp-dashboard {

    .mat-cell,
    .mat-header-cell,
    .dashboard-mobile-label {
        font-size: 1.125rem !important;
        line-height: 1.75rem !important;
    }

    .mat-cell:last-of-type {
        padding-right: 5px !important;
    }

    .mat-column-name {
        flex: 0 0 20%;
    }

    .mat-column-status {
        flex: 0 0 15%;
    }

    .dashboard-mobile-label {
        color: rgba(0, 0, 0, 0.54);
    }

    .mat-row {
        align-items: flex-start !important;
    }

    .dashboard-status-container__img {
        height: 25px !important;
        width: 25px !important;
        padding: 0 8px 0 0;
    }

    .Link {
        color: mat.get-color-from-palette($app-theme, 200);
        white-space: normal;
    }

    @media(max-width: 780px) {
        .button_small {
            font-size: 1.125rem;
            line-height: 1.75rem;
        }
    }

    @media(max-width: 650px) {
        .mat-column-actions {
            .dashboard-mobile-label {
                line-height: 1.125rem !important;
            }
        }
    }
}

.dashboard-activity-button {
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-family: inherit;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    text-decoration: none;
    text-align: left;
    font-family: 'Untitled-Sans-Regular', sans-serif !important;
}

.dashboard-activity-button:hover {
    color: mat.get-color-from-palette($app-theme, 400) !important;
}